import React from "react"
import LayoutNew from "../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function Thoughts() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>
          Thoughts 💜🐄
        </title>
      </Helmet>

      <section className="tought-sec">
        <h1 className="h1">Thoughts</h1>
      </section>

      <section className="tought-sec2 py-20">
        <div className="container mx-auto px-4">
            <div
                className="grid grid-cols-1 grid-rows-1 md:grid-cols-3 md:grid-rows-3 sm:grid-cols-2 sm:grid-rows-2 gap-8">

                <a href="/thoughts/Back-To-School-With-Purple-Cow-Internet" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Four-Reasons-Why-Students-Choose-Purple-Cow.png"
                        alt="Four Reasons Why Students Choose Purple Cow"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Back to School with Purple Cow Internet</h4>
                        <p className="text-sm my-2">June 10th 2024:</p>
                        <p className="text-base line-clamp-3">
                            As the back-to-school season approaches, it’s time for new wardrobes, new school supplies,
                            and most
                            importantly… new internet.
                        </p>
                    </div>
                </a>

                <a href="/thoughts/Love-our-internet-or-its-free" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/love-our-internet-or-its-free.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Love our Internet or it's free! </h4>
                        <p className="text-sm my-2">June 10th 2024:</p>
                        <p className="text-base line-clamp-3">
                            We’ve got some big news at Purple Cow Internet, and we’re thrilled to share it with you! We
                            are confident that our internet service is so awesome that we’re willing to make a bold
                            promise

                        </p>
                    </div>
                </a>


                <a href="/thoughts/Why-Building-Owners-Encourage-Certain-Internet-Service-Providers-Over-Others"
                   className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/no-choice.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Why is there Only One Internet Provider in my New
                            Apartment? </h4>
                        <p className="text-sm my-2">May 19th 2024:</p>
                        <p className="text-base line-clamp-3">
                            In short it often comes down to money and relationships. Let me explain why. When you move
                            into a new apartment building, you might find that the building owner encourages you to go
                            with a specific internet service provider (ISP). This preference is not just about service
                            quality but is rooted in the process of how ISPs gain access to buildings and the financial
                            agreements involved.

                        </p>
                    </div>
                </a>

                <a href="/thoughts/The-Price-of-Connectivity-How-Canada-Compares-Globally"
                   className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/The-Price-of-Connectivity-How-Canada-Compares-Globally.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">The Price of Connectivity: How Canada Compares
                            Globally </h4>
                        <p className="text-sm my-2">May 1st 2024:</p>
                        <p className="text-base line-clamp-3">
                            In an era where internet access is nearly as essential as electricity, Canadians face a
                            stark reality: they pay some of the highest rates for internet and cellular services in the
                            world. This issue stems largely from a market dominated by a few large corporations that
                            limit competition and drive up pricing.

                        </p>
                    </div>
                </a>


                <a href="/thoughts/Wanderers-Partnership-with-Purple-Cow-Internet" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Wanderers-partnership.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Purple Cow Internet Kicks Off Partnership with The
                            Wanderers </h4>
                        <p className="text-sm my-2">April 22nd 2024:</p>
                        <p className="text-base line-clamp-3">
                            We're thrilled to announce that Purple Cow Internet is now a proud sponsor of the Halifax
                            Wanderers, the city's beloved professional soccer team! This partnership marks a significant
                            stride in our continuous effort to support and invest in our community, aligning with local
                            passions and the spirited dynamism of Halifax
                        </p>
                    </div>
                </a>


                <a href="/thoughts/Why-Purple-Cow-Has_the_best_team" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Why-Purple-Cow-has-the-best-team.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Why Purple Cow's Team is the Best! </h4>
                        <p className="text-sm my-2">April 17th 2024:</p>
                        <p className="text-base line-clamp-3">
                            At Purple Cow Internet, we take pride in our exceptional team that drives our success. Our
                            fully
                        </p>
                    </div>
                </a>


                <a href="/thoughts/Bluenose-Partnership" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Purple-Cow-5k-race.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Join Us at the Bluenose 5K </h4>
                        <p className="text-sm my-2">April 11th 2024:</p>
                        <p className="text-base line-clamp-3">
                            We are thrilled to announce that Purple Cow Internet is a proud sponsor of this year’s
                            Bluenose 5K
                        </p>
                    </div>
                </a>

                <a href="/thoughts/Moving-Made-Easy" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Moving-With-Purple-Cow-Internet.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Moving Made Easy </h4>
                        <p className="text-sm my-2">April 1st 2024:</p>
                        <p className="text-base line-clamp-3">
                            Moving to a new home is an exciting journey filled with fresh starts and new opportunities.
                            At Purple Cow Internet,
                        </p>
                    </div>
                </a>

                <a href="/thoughts/How-Switching-to-Purple-Cow-Internet-Can-Transform-Internet-Access-in-Nova-Scotia"
                   className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/band-together-inflict-change-together.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">How Switching to Purple Cow Internet Can Transform
                            Internet Access in Nova Scotia </h4>
                        <p className="text-sm my-2">February 26th 2024:</p>
                        <p className="text-base line-clamp-3">
                            In an era dominated by large corporations focused on maximizing profits, a small yet mighty
                            challenger is making waves in Nova Scotia's internet landscape
                        </p>
                    </div>
                </a>

                <a href="/thoughts/10-Unique-Ways-to-Save-Money-in-2024-Without-Giving-Up-Coffee"
                   className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/10-ways-to-save-money.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">10 Ways to Save Money in 2024 </h4>
                        <p className="text-sm my-2">December 24th 2023:</p>
                        <p className="text-base line-clamp-3">
                            In 2024, everyone's looking to save a bit more, but often the most effective tips are the
                            ones least talked about. Here are some ingenious strategies I personally use, potentially
                            saving you up to $11,000 a year:
                        </p>
                    </div>
                </a>


                <a href="/thoughts/2023-recap" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Thank-you-2023-recap.png"
                        alt="Thank you 2023 recap Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">2023 Recap & Thank you! </h4>
                        <p className="text-sm my-2">December 22nd 2023:</p>
                        <p className="text-base line-clamp-3">
                            As the year draws to a close, we at Purple Cow Internet find ourselves reflecting on the
                            incredible journey we've embarked upon, made possible only through the unwavering support of
                            each one of you - our cherished herd.As the year draws to a close, we at Purple Cow Internet
                            find ourselves reflecting on the incredible journey we've embarked upon, made possible only
                            through the unwavering support of each one of you - our cherished herd.
                        </p>
                    </div>
                </a>


                <a href="/thoughts/2023-Personal-Annual-Report" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/7-simple-questions.png"
                        alt="AI Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">2023 Personal Annual Report </h4>
                        <p className="text-sm my-2">December 16th 2023:</p>
                        <p className="text-base line-clamp-3">
                            7 simple questions to help you reflect on the past year and make the next year unstoppable
                        </p>
                    </div>
                </a>


                <a href="/thoughts/Do-I-really-need-to-pay-for-a-Gig" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/1gbps.png"
                        alt="AI Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Do I really need a Gig Internet Connection? </h4>
                        <p className="text-sm my-2">November 25th 2023:</p>
                        <p className="text-base line-clamp-3">
                            Internet service providers are going to try to get you to spend the most money possible by
                            encouraging you to signup for the highest bandwidth package. At Purple Cow Internet we want
                            to right size your internet package so you save money. First, let's understand what "mbps"
                            means. Mbps stands for megabits per second, and it's a measure of how much data can be sent
                            or received over your internet connection in one second. Now, let’s break down these
                            numbers:
                        </p>
                    </div>
                </a>


                <a href="/thoughts/What-is-wifi-6" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/wifi6-Purple-Cow-Internet.png"
                        alt="AI Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">What is Wifi 6? </h4>
                        <p className="text-sm my-2">November 12th 2023:</p>
                        <p className="text-base line-clamp-3">
                            WiFi 6, or IEEE 802.11ax, stands as the cutting edge of wireless internet standards,
                            bringing faster speeds, increased device handling, and better performance in crowded areas,
                            among other benefits. For an innovative company like Purple Cow Internet, capitalizing on
                            such advancements is integral to their mission.
                        </p>
                    </div>
                </a>


                <a href="/thoughts/The-AI-Revolution-in-the-ISP-Sphere" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/ai-purple-cow.png"
                        alt="AI Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">The AI Revolution in the ISP Sphere </h4>
                        <p className="text-sm my-2">November 7th 2023:</p>
                        <p className="text-base line-clamp-3">
                            In the realm of digital connectivity, artificial intelligence (AI) is set to ignite a
                            paradigm shift in the Internet Service Provider (ISP) industry, especially in the domain of
                            customer service. For too long, customers have endured the frustration of navigating complex
                            automated systems, enduring lengthy waits and often inefficient support. But the tide is
                            turning.
                        </p>
                    </div>
                </a>


                <a href="/thoughts/Empowering-Communities-Beyond-Connectivity" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/donation-image.png"
                        alt="Purple cow internet donation"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Empowering Communities Beyond Connectivity</h4>
                        <p className="text-sm my-2">October 25th 2023:</p>
                        <p className="text-base line-clamp-3">
                            At Purple Cow Internet, our commitment runs deeper than just providing unparalleled internet
                            services. We believe that empowerment extends to uplifting and enriching our broader
                            community. Our guiding philosophy is profound in its simplicity: to attain our desires in
                            this world, we first ensure that others achieve theirs.
                        </p>
                    </div>
                </a>


                <a href="/thoughts/Peacetime-vs-wartime-ceo" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/peacetime-CEO-vs-Wartime-CEO.png"
                        alt="Peacetime CEO vs Wartime CEO"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">As CEO sometimes things don't go to plan!</h4>
                        <p className="text-sm my-2">October 22nd 2023:</p>
                        <p className="text-base line-clamp-3">
                            In the world of business, let's be honest: sometimes, things hit the fan. No matter how well
                            you plan or how far you see into the future, you can still
                        </p>
                    </div>
                </a>


                <a href="/thoughts/Finalist-for-business-leader" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/Leader-purple-cow.png"
                        alt="Our CEO is a finalist for the Business Leader of the Year"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Our CEO is a finalist for the Business Leader of the
                            Year</h4>
                        <p className="text-sm my-2">October 18th 2023:</p>
                        <p className="text-base line-clamp-3">
                            I'm thrilled to share that alongside four other outstanding individuals I've been named a
                            finalist for the "Business Leader of the Year Award" by the Halifax Chamber of Commerce.
                            It's truly an honor for Purple Cow and a testament to the impact everyone on our team is
                            making in our community. But here's the kicker: with utmost humility, "We are just getting
                            started."
                        </p>
                    </div>
                </a>


                <a href="/thoughts/ISP-provided-emails" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Breaking-free-from-ISP-provided-emails.png"
                        alt="How to get the big Internet Service Providers to lower their bill"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">How to Break Free from ISP-Provided Emails</h4>
                        <p className="text-sm my-2">September 21st 2023:</p>
                        <p className="text-base line-clamp-3">
                            Are you paying hundreds of extras of dollars a year just to avoid levaing your
                            ISP provided email? The time to switch is now with these easy steps.
                        </p>
                    </div>
                </a>

                <a href="/thoughts/90-days-since-launching-purple-cow-tv" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/Purple-Cow-TV.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">90 days since launching Purple Cow TV</h4>
                        <p className="text-sm my-2">August 25th 2023</p>
                        <p className="text-base line-clamp-3">[Halifax, May 26th 2023] - Purple Cow Internet introduces
                            its groundbreaking TV solution today. The innovative offering grants customers access to all
                            Corus TV Channels through a simple downloadable app </p>
                    </div>
                </a>

                <a href="/thoughts/How-to-reduce-your-internet-bill" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/Purple-Cow-Internet-bill-picture.png"
                        alt="How to get the big Internet Service Providers to lower their bill"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">How to Reduce your Internet Bill</h4>
                        <p className="text-sm my-2">August 17th 2023:</p>
                        <p className="text-base line-clamp-3">
                            Are you currently paying too much with your internet service
                            provider? Have you tried everything to lower your bill? Give this
                            article a read for a few helpful tips on how to reduce your
                            Internet bill.
                        </p>
                    </div>
                </a>

                <a href="/thoughts/How-to-solve-wifi-issues" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/Wifi-Issues.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">How to Solve Wifi Issues</h4>
                        <p className="text-sm my-2">July 13th 2023</p>
                        <p className="text-base line-clamp-3">
                            In today's hyper-connected world, a strong and reliable Wi-Fi connection is essential for
                            both work and leisure activities. However, it's not uncommon to encounter situations where
                            your Wi-Fi signal doesn’t reach all areas of your home. Fortunately, there are several
                            troubleshooting
                        </p>
                    </div>
                </a>

                <a href="/thoughts/Importance-of-competition-in-halifaxs-internet-market"
                   className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="images/Competition-purple-cow-internet.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Importance of Competition in Halifax's Internet
                            Market</h4>
                        <p className="text-sm my-2">July 9th 2023</p>
                        <p className="text-base line-clamp-3">
                            As an owner of Purple Cow Internet, I believe it is crucial to emphasize the importance of
                            having businesses like ours offering services in Nova Scotia at lower rates compared to our
                            competitors. Currently,
                        </p>
                    </div>
                </a>

                <a href="/thoughts/July-3rds-service-interruption" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/July-3rds-Internet-Service.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Details Surrounding July 3rds Internet Service
                            Interruption</h4>
                        <p className="text-sm my-2">July 5th 2023</p>
                        <p className="text-base line-clamp-3">
                            In today's hyper-connected world, a strong and reliable Wi-Fi connection is essential for
                            both work and leisure activities. However, it's not uncommon to encounter situations where
                            your Wi-Fi signal doesn’t reach all areas of your home. Fortunately, there are several
                            troubleshooting
                        </p>
                    </div>
                </a>

                <a href="/thoughts/Purple-Cow-Internet-Expands-its-Services-to-Prince-Edward-Island-and-Newfoundland"
                   className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/map-img.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Purple Cow Internet Expands its Services to Prince Edward
                            Island and Newfoundland</h4>
                        <p className="text-sm my-2">June 12th 2023</p>
                        <p className="text-base line-clamp-3">Halifax, June 14th 2023 – Purple Cow Internet, Canada's
                            highest rated internet service provider according to Google, is thrilled to announce its
                            expansion into Prince Edward Island and Newfoundland.
                        </p>
                    </div>
                </a>

                <a href="/thoughts/Purple-Cow-Internet-Introduces-Home-Phone" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/phone-banner.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Purple Cow Internet Introduces Home Phone</h4>
                        <p className="text-sm my-2">June 5th 2023</p>
                        <p className="text-base line-clamp-3">In the realm of modern communication, the Internet has
                            undoubtedly become the backbone of our digital lives. However, as technology continues to
                            evolve, Purple Cow Internet has risen to the occasion by recognizing the </p>
                    </div>
                </a>

                <a href="/thoughts/Purple-Cow-Internet-Launches-TV-Solution" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/tv-blog-post.png"
                        alt="AI Purple Cow Internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Purple Cow Internet Launches Revolutionary TV
                            Solution</h4>
                        <p className="text-sm my-2">May 25th 2023</p>
                        <p className="text-base line-clamp-3">[Halifax, May 26th 2023] - Purple Cow Internet introduces
                            its groundbreaking TV solution today. The innovative offering grants customers access to all
                            Corus TV Channels through a simple downloadable app </p>
                    </div>
                </a>

                <a href="/thoughts/The-Ideal-Choice-for-Newcomers-in-Nova-Scotia-Prince-Edward-Island-and-Newfoundland"
                   className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Purple-Cow-Internet-best-internet-provider.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">The Ideal Choice for Newcomers</h4>
                        <p className="text-sm my-2">May 10th 2023</p>
                        <p className="text-base line-clamp-3">Moving to a new place brings with it a multitude of
                            challenges, and one of the most critical considerations is ensuring a reliable internet
                            connection. In the Canadian provinces of Nova Scotia </p>
                    </div>
                </a>

                <a href="/thoughts/Why-Purple-Cow-Internet-is-the-best-internet-service-provider-in-Nova-Scotia"
                   className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/1-star-review.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Why Purple Cow Internet is the best Internet Service
                            Provider </h4>
                        <p className="text-sm my-2">April 7th 2023</p>
                        <p className="text-base line-clamp-3">When it comes to internet service providers in Halifax,
                            Nova Scotia, Purple Cow Internet stands out from the crowd. There are many reasons why our
                            customers consistently rate us as the best ISP in the region </p>
                    </div>
                </a>

                <a href="/thoughts/Why-Purple-Cow-Internet" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Why-Purple-Cow-Internet.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Why Purple Cow Internet</h4>
                        <p className="text-sm my-2">March 5th 2023</p>
                        <p className="text-base line-clamp-3">Well I’m here to answer just that question. It started
                            about 5 years ago after moving home to Nova Scotia from the United States. After researching
                            available providers I was blown away by the high price of internet in Nova </p>
                    </div>
                </a>

                <a href="/thoughts/Why-Purple-Cow's-the-Best" className="flex flex-col rounded ">
                    <StaticImage
                        layout="constrained"
                        className="rounded h-40 w-fill hover:opacity-75 transition-opacity duration-300 ease-in-out"
                        src="thoughts/images/Why-Purple-Cow-Internet-is-the-Best.png"
                        alt="Wifi logos for Purple Cow Internet cheap internet"
                    />
                    <div className="p-4 relative">
                        <h4 className="text-[20px] font-bold">Why Purple Cow Internet is the Best</h4>
                        <p className="text-sm my-2">Feb 12th 2023</p>
                        <p className="text-base line-clamp-3">As a resident of Nova Scotia, you know how important it is
                            to have reliable internet service. Whether you’re running a business or simply streaming
                            your favorite shows, a fast and dependable internet </p>
                    </div>
                </a>
            </div>
        </div>
      </section>
    </LayoutNew>
  )
}